import { Lang } from './types';

const it: Lang = {
  /*login Credential*/
  login: 'Accesso',
  login_now: 'Accesso',
  log_in: 'Accedi',
  password: 'Password',
  email: 'Email',
  your_email_address: 'Il tuo indirizzo email',
  your_password: 'La tua password',
  forgot_password: 'Password dimenticata?',
  back_to_login: 'Torna al login',
  password_reset: 'Richiedi il reset della password',
  login_message: 'Inserisci le tue credenziali per accedere a {0}.',
  forgot_message: "Inserisci l'email che utilizzi per accedere al tuo account.",
  immo_portal: 'Immobilienportal',
  password_info:
    'La password deve avere almeno 1 numero, 1 carattere speciale, 1 lettera maiuscola e 5 altri caratteri.',
  invalid_login_msg: 'Email o password non corrispondenti.',
  user_delete_error:
    "L'utente non può essere eliminato in quanto sta detenendo le azioni MMS",
  change_language: 'Cambia lingua',
  invalid_token: 'Questo token di reset della password non è valido.',
  two_factor_authentication: 'Autenticazione a due fattori',
  enter_the_pin_from_google_authentication:
    'Inserisci il codice da Google Authentication.',
  authenticate: 'Autenticare',
  one_time_password: 'Password a tempo',
  FA_status: 'Stato 2FA',
  disable_2FA: 'Disattiva 2FA',
  enable_2FA: 'Abilita 2FA',
  fa_message:
    "Per abilitare l'autenticazione a due fattori, scansiona questo codice QR con l'app Google Authenticator.",
  code: 'Codice',
  fa_status_tooltip: 'Genera la chiave segreta per abilitare 2FA',
  authenticator_code: 'Codice di autenticazione',
  FA_successfully: '2FA è stato abilitato con successo',
  FA_enable: '2FA è attualmente abilitato per il tuo account',
  fa_Disable: '2FA ora è disabilitato',
  authenticator_code_error: 'Errore codice di autenticazione',
  required_authenticator_code: 'Codice di autenticazione richiesto',
  fa_disable_title:
    "Se stai cercando di disabilitare l'autenticazione a due fattori, conferma la tua password e fai clic sul pulsante Disattiva 2FA.",
  activate_2FA: 'Attiva 2FA',
  deactivate_2FA: 'Disattiva 2FA',

  /*button*/
  save: 'Salva',
  save_now: 'Salva ora',
  filter: 'Filtro',
  back: 'Indietro',
  editing: 'Modifica',
  apply_filter: 'Applica filtro',
  reset_filter: 'Ripristina filtro',
  more_filter: 'Altri filtri',
  cancel: 'Annulla',
  clear: 'Cancella',
  reply: 'Rispondi',
  comment: 'Commenta',
  save_and_create_new_ticket: 'Salva e crea un nuovo ticket',
  confirm: 'Conferma',
  profile: 'Profilo',
  my_profile: 'Il mio profilo',
  edit_profile: 'Modifica profilo',
  profile_updated: 'Profilo aggiornato.',
  profle_update_failed: 'Aggiornamento profilo non riuscito.',
  password_updated: 'Password aggiornata.',
  not_match_new_confirm_password: 'Le password non corrispondono.',
  incorrect_password: 'Password non corretta.',
  setting: 'Impostazione',
  settings: 'Impostazioni',
  logout: 'Esci',
  loggedOut: 'Disconnesso',
  change_password: 'Cambia password',
  change_now: 'Cambia ora',
  Current_password: 'Password attuale',
  new_password: 'Nuova password',
  confirm_password: 'Conferma password',
  invalid_current_password: 'Password attuale non valida.',
  euro: 'Euro',
  euro_symbol: '€',
  frank_symbol: '₣',
  email_not_found: 'Indirizzo e-mail non trovato.',
  email_required: "L'indirizzo e-mail è richiesto",

  /*Module*/
  user_management: 'Gestione utenti',
  ticket: 'Biglietti',
  dashboard: 'Dashboard',

  /*Splash Screen*/
  choose_subsystem: 'Scegliere il sottosistema',

  /*User Management*/
  user_management_system: 'Sistema di gestione degli utenti',
  user: 'Utenti',
  user_list: 'Elenchi utenti',
  user_rights: "Diritti dell'utente",
  user_roles: 'Ruoli utente',
  user_name: 'Nome utente',
  user_profile: 'Profilo utente',
  user_details: 'Dettagli utente',
  role_details: 'Dettagli ruolo',
  role: 'Ruolo',
  add_additional_role: 'Aggiungi ruolo aggiuntivo',
  add_additional_rights: 'Aggiungi diritti aggiuntivi',
  additional_rights: 'Diritti aggiuntivi',
  manage_extra_rights: 'Gestisci diritti extra',
  right_from_role: 'Diritti dal ruolo',
  add_role: 'Aggiungi ruolo',
  role_setup: 'Impostazione del ruolo',
  system: 'Sistema',
  role_name: 'Nome del ruolo',
  privillage: 'Privillage',
  add_user: 'Aggiungi utente',
  all_users: 'Tutti gli utenti',
  all_user_with_that_role: 'Tutti gli utenti con quel ruolo',
  add_new_user: 'Aggiungi nuovo utente',
  add_new_role: 'Aggiungi un nuovo ruolo',
  view_user: 'Visualizza utente',
  edit_user: 'Modifica utente',
  delete_user: 'Elimina utente',
  save_user: 'Salva utente',
  upload_image: 'Carica immagine',
  drop_image_or_click_to_select_image:
    "Rilascia immagine o clicca per selezionare l'immagine",
  assigned_tickets: 'Biglietti assegnati',
  access_user: 'Accesso utente',
  manage_user: "Gestire l'utente",
  access_user_log: 'Accesso al registro utenti',
  manage_owner: 'Gestire il proprietario',
  manage_admin: "Gestire l'amministrazione",
  manage_caretaker: 'Gestire il custode',
  access_oms: "Accesso all'OMS",
  manage_oms: "Gestire l'OMS",
  access_dms_ownership: 'Accesso ai documenti',
  manage_dms_ownership: 'Gestione dei documenti',
  access_dms_owner: 'Accesso ai messaggi',
  manage_dms_owner: 'Gestione dei messaggi',
  access_faq: "FAQ sull'accesso",
  manage_faq: 'Gestire le FAQ',
  access_faq_group: 'Accesso ai gruppi di FAQ',
  manage_faq_group: 'Gestire i gruppi di FAQ',
  access_ticket: 'Leggi i biglietti',
  assign_ticket: 'Assegnare biglietti',
  manage_own_owner_information: 'Modificare i propri dati',
  access_own_owner_object: 'Accesso ai propri oggetti',
  access_own_owner_document: 'Accesso ai propri documenti',
  access_newsletter: 'Vedi Newsletter e bozze',
  manage_newsletter: 'Modifica newsletter e bozze',
  action: 'Azione',
  first_name: 'Nome',
  last_name: 'Cognome',
  email_address: 'Indirizzo e-mail',
  valid_email_address: 'Si prega di fornire un indirizzo e-mail valido',
  contact_info: 'Informazioni di contatto',
  address: 'Indirizzo',
  view: 'Vista',
  add: 'Aggiungi',
  edit: 'Modifica',
  delete: 'Elimina',
  search: 'Cerca',
  login_with_new_password: 'Effettua il login con una nuova password',
  msg_valid_password: 'Si prega di fornire una password valida',
  msg_password_reset_link:
    'Abbiamo inviato per e-mail il link per la reimpostazione della password!',
  select_role: 'Seleziona ruolo',
  rights: 'Diritti',
  reset_password: 'Reimposta password',
  reset_password_subtitle:
    'Inserisci i campi che usi per resettare il tuo account',
  save_reset_password: 'Salva la password di reset',
  create_password: 'Crea password',
  create_password_subtitle:
    'Inserisci i campi per creare una password per il tuo account',
  save_password: 'Salva password',
  password_helper_text:
    'Minimo 8 caratteri, almeno uno maiuscolo, uno minuscolo, un numero e un carattere speciale',
  resend_verification_email: "Reinvia l'e-mail di verifica",
  verification_email_sent: 'Email di verifica inviata',
  email_verification_success: 'Email verificata con successo',
  email_verification_failed: "Verifica dell'e-mail non riuscita",
  resend_verify_email_error: "Errore nel reinvio dell'e-mail di verifica",
  resend_verify_email_success:
    "L'e-mail di verifica è stata inviata con successo. Controlla la tua casella di posta.",
  resend_verify_email_success_to:
    "L'e-mail di verifica è stata inviata con successo a",
  email_already_verified: "L'e-mail è già verificata",
  login_to_continue: 'Effettua il login per continuare',
  create_password_to_proceed: 'Crea una password per procedere',
  change_confirm: 'Sei sicuro di voler cambiare questa password?',
  cellphone: 'Cellulare',
  invalid_cellphone: 'Cellulare non valido',
  email_placeholder: 'youremail@example.com',
  admin: 'Amministratore',
  verified: 'Verificato',
  not_yet_verified: 'Non ancora verificato',

  user_login_log: 'Log di accesso utente',
  all_login_logs: 'Tutti i registri di accesso',
  correspondence_language: 'Lingua di corrispondenza',
  correspondence_language_placeholder:
    'Selezionare la lingua di corrispondenza',
  correspondence_language_required: 'La lingua di corrispondenza è richiesta',

  adittion: 'Adittion',
  deceased: 'Deceased',
  deceased_since: 'Deceased Since',
  address_forwarding: 'Address Forwarding',
  data_sharing: 'Condivisione dati',
  data_sharing_activated: 'Condivisione dati attivata',
  owner_created: 'Owner has been created',
  employee_with_ticket_support: 'Employee With Ticket Support',
  salutation: 'Saluto',
  select_salutation: 'Seleziona il saluto',
  salutation_is_required: 'Il saluto è richiesto',
  valid_only_number: 'Si prega di inserire solo numeri',
  city_is_required: 'La città è richiesta',
  notes_notizen: 'Note',
  notes_cannot_be_more_than_2000_characters_long:
    'Le note non possono essere più lunghe di 2000 caratteri',
  max_characters_exceeded: 'Limite massimo di caratteri superato',
  add_new_owner: 'Aggiungi nuovo proprietario',
  add_new: 'Aggiungi nuovo {0}',
  employee: 'Impiegato',
  caretaker: 'Custode',
  deceased_since_is_required: 'Deceased since is required',
  owner_updated: 'Owner has been updated',
  owner_details: 'Owner Details',
  edit_owner: 'Edit Owner',
  send_verification_email: 'Send Invitation Email',
  email_verification_sent: 'Email Verification sent',

  address_forwarding_reason_guardianship: 'Guardianship',
  address_forwarding_reason_temporary_change_of_address:
    'Temporary change of address',
  address_forwarding_reason_different_billing_address:
    'Different billing address',
  address_forwarding_reason_address_of_the_property: 'Address of the property',

  reason: 'Reason',
  select_reason: 'Select reason',
  reason_is_required: 'Reason is required',

  customer_number: 'Customer Number',
  customer_number_is_required: 'Customer number is required',
  owner_since: 'Proprietario dal',
  required_owner_since: '"Proprietario dal" richiesto',

  discard_changes: 'Discard changes',
  discard_changes_warning: 'Do you really want to discard your changes?',
  discard: 'Discard',
  no_thank_you: 'No, thank you',
  caretaker_created: 'Custode è stato creato',
  add_caretaker: 'Aggiungi nuovo custode',
  caretaker_details: 'Dettagli Custode',
  edit_caretaker: 'Modifica Custode',

  sn: '#',
  name: 'Nome',
  assignee: 'Cessionario',
  description: 'Descrizione',
  yes: 'Sì',
  no: 'No',
  active: 'Attivo',
  in_active: 'Inattivo',
  select_apartment: 'Seleziona appartamento',
  select_unit: "Seleziona l'unità",
  select_assignee: 'Seleziona assegnatario',
  select_owner: 'Seleziona il proprietario',
  select_priority: 'Seleziona priorità',
  select_source: 'Seleziona fonte',
  write_description: 'Scrivi descrizione',
  csv_datasharing: 'CSV Condivisione dei dati',

  /*Ticket Board*/
  ticket_system: 'Sistema di biglietti',
  all_tickets: 'Tutti i biglietti',
  ticket_board: 'Schede biglietti',
  add_task: 'Aggiungi attività',
  open: 'Aperto',
  inprogress: 'In corso',
  'in-progress': 'In corso',
  done: 'Fatto',
  closed: 'Chiuso',
  document: 'Documento',
  normal_task: 'Attività normale',
  create_ticket: 'Crea biglietto',
  ticket_details: 'Dettagli del biglietto',
  ticket_title: 'Titolo del biglietto',
  object: 'Oggetto',
  all_objects: 'Tutti gli oggetti',
  customer: 'Cliente',
  house_owner: 'Proprietario della casa',
  priority_label: 'Etichetta di priorità',
  priority: 'Priorità',
  upload_files: 'Carica file',
  descriptions: 'Descrizioni',
  save_create_new_ticket: 'Salva e crea un nuovo biglietto',
  author: 'Autore',
  created_by: 'Creato da',
  created_at: 'Creato a',
  last_modified_by: 'Ultima modifica da',
  last_modified_at: 'Ultima modifica a',
  last_changed_by: 'Ultima modifica da',
  last_changed_at: 'Ultima modifica a',
  status: 'Stato',
  ticket_log: 'Log',
  ticket_list: 'Elenchi di biglietti',
  repeat_task: 'Attività di ripetizione',
  apartment: 'Appartamento',
  apartments: 'Appartamenti',
  unit: 'Unità',
  ticket_created: 'Il biglietto è stato creato',
  has_been_created: 'è stato creato',
  has_not_been_created: 'non è stato creato',
  has_been_merged: 'è stato unito',
  has_not_been_merged: 'non è stato unito',
  has_been_updated: 'è stato aggiornato',
  has_not_been_updated: 'non è stato aggiornato',
  has_been_deleted: 'è stato cancellato',
  has_not_been_deleted: 'non è stato cancellato',
  ticket_error: 'Il biglietto non è stato creato',
  expand: 'Espandi',
  ticket_list_error:
    "Si è verificato un errore nell'elaborazione della richiesta.",
  title_required: 'Il titolo è richiesto',
  house_owner_is_required: 'È richiesto il proprietario della casa.',
  repeating_info: 'Informazioni ripetute',
  interval: 'Intervallo',
  repeat_from: 'Ripetizione da',
  repeat_until: 'Ripeti fino a quando',
  comment_here: 'Commenta qui...',
  comment_required: 'Il commento è richiesto',
  id_not_found: 'ID non trovato',
  edit_ticket: 'Modifica biglietto',
  ticket_updated: 'Biglietto aggiornato con successo',
  change_status: 'Cambia stato',
  status_updated: 'Stato aggiornato con successo',
  select_status: 'Seleziona stato',
  comment_error: 'Errore di commento',
  view_more: 'Visualizza di più',
  view_less: 'Visualizza di meno',
  spent_time: 'Tempo speso',
  adjust_time_spent: 'Regola il tempo speso',
  correct_spent_time: 'Tempo speso corretto',
  spent_time_message: 'Il tempo trascorso è stato corretto manualmente',
  task: 'Attività',
  low: 'Basso',
  medium: 'Medio',
  high: 'Alto',
  urgent: 'Urgente',
  select_type: 'Seleziona tipo',
  select_type_art: 'Seleziona tipo',
  ticket_detail: 'Dettaglio biglietto',
  edit_task: 'Modifica attività',
  created_ticket: 'biglietto creato',
  updated_ticket: 'biglietto aggiornato',
  changed_status: 'stato modificato',
  assigned_ticket: 'biglietto assegnato',
  commented: 'commentato',
  order_by: 'Ordine da',
  createdat: 'Creato presso',
  order: 'Ordine',
  asc: 'Ascendente',
  desc: 'Discendente',
  showing_all_options: 'Mostra tutte le opzioni',
  appointment: 'Appuntamento',
  appointment_date_time: "Data e ora dell'appuntamento",
  enter_appointment_date_time: "Inserire la data e l'ora dell'appuntamento",
  full_screen: 'Schermo intero',
  add_new_ticket: 'Aggiungi un nuovo biglietto',

  source: 'Origine',
  destination: 'Destinazione',
  street: 'Strada',
  house_no: 'Numero di casa',
  zip_code: 'Codice postale',
  town: 'Città',
  country: 'Paese',
  phone: 'Numero di telefono',
  zip_code_abbreviation: 'CAP',

  add_now: 'Aggiungi ora',
  date_time: 'Data/Ora',
  city: 'Città',
  file: 'File',
  images: 'Immagini',
  upload_file: 'Carica file',
  question: 'Domanda',
  from: 'Da',
  to: 'A',
  article: 'Articolo',
  type: 'Tipo',
  objects: 'Oggetti',
  object_id: 'ID Oggetto',
  tickets: 'Biglietti',
  ticket_source: 'Fonte biglietto',

  required: 'Richiesto',
  user_created: "L'utente è stato creato",
  user_creation_failed: 'Creazione utente non riuscita.',
  user_udpate_failed: 'Aggiornamento utente non riuscito.',
  password_update_failed: 'Aggiornamento password fallito',
  user_not_found: 'Utente non trovato',
  update: 'Aggiornamento',
  user_updated: 'Utente aggiornato con successo',
  user_deleted: "L'utente è stato cancellato",
  roles_not_found: 'Ruoli non trovati',
  confirm_delete: 'Sei sicuro di volerlo cancellare?',

  select: 'Seleziona',
  collapse_sidebar: 'Riduci la barra laterale',
  expand_sidebar: 'Espandi la barra laterale',
  max_upload_size_5: 'Dimensione massima degli allegati di upload fino a 5 MB.',
  max_upload_size_10:
    'Dimensione massima degli allegati di upload fino a 10 MB.',
  max_upload_size_15:
    'Dimensione massima degli allegati di upload fino a 15 MB.',
  max_upload_size_100:
    'Dimensione massima degli allegati di upload fino a 100 MB.',
  max_upload_size_1GB:
    'Dimensione massima degli allegati di upload fino a 1 GB.',
  file_upload_failed: 'Caricamento file non riuscito',

  drag_drop_files_msg:
    'Trascinare e rilasciare i file nel riquadro o fare clic sul "+" per selezionare i file.',
  files: 'File',
  delete_file: 'Elimina file',
  only_1_file_is_allowed: 'È consentito solo 1 file.',
  uploading: 'Caricamento',
  invalid_profile_upload: 'Caricamento del profilo non valido',
  file_must_be_an_image_file: 'Il file deve essere di tipo immagine.',
  clear_profile_pic: 'Cancella immagine del profilo',
  are_you_sure:
    'Sei sicuro? Non potrai annullare questa azione successivamente.',
  upload_label: 'Carica profilo',
  remove_label: 'Rimuovi profilo',
  invalid_file_size: 'Dimensione del file non valida',
  file_size_info: 'La dimensione del file deve essere inferiore a 5 MB',
  uploading_profile: 'Caricamento del profilo',
  no_permission_msg:
    "Non hai il permesso di accedervi. Si prega di contattare l'amministratore.",
  house_number: 'Numero di casa',
  house: 'Casa',
  flat: 'Appartamento',
  fresh: 'Fresco',
  consumed: 'Consumato',
  general_information: 'Informazioni generali',
  change_ownership: 'Cambio di proprietàx',
  oil_level_report: "Rapporto sul livello dell'olio",
  malfunction_defect_deficiencies: 'Malfunzionamento, Difetto, Carenza',

  // common
  lang: 'it',
  english: 'Inglese',
  german: 'Tedesco',
  polish: 'Polacco',
  french: 'Francese',
  italian: 'Italiano',
  result: 'Risultato',
  no_results_found: 'Nessun risultato trovato.',
  no_records_found: 'Nessun record trovato',
  error: 'Errore',
  no_options: 'Nessuna opzione',
  showing: 'Mostrando',
  manually: 'Manualmente',
  general: 'Generale',
  gotoDetails: 'Vai ai dettagli',
  out_of: 'fuori da',
  loading: 'Caricamento...',
  show_rows: 'Mostra righe',
  error_boundary_heading_text: 'Siamo spiacenti, qualcosa è andato storto.',
  error_boundary_paragraph_text:
    'Il nostro team è stato informato e lo risolveremo il prima possibile.',
  reload_page: 'Ricarica pagina',
  take_me_home: 'Portami a casa',
  manual: 'Manuale',
  ['v-office']: 'V-Office',
  guest: 'Ospite',
  owner: 'Proprietario',
  lead: 'Condurre',
  supplier: 'Fornitore',
  test: 'Prova',
  other: 'Altro',
  daily: 'Giornaliero',
  weekly: 'Settimanale',
  bi_weekly: 'Ogni due settimane',
  monthly: 'Mensile',
  semi_anually: 'Semestrale',
  anually: 'Annualmente',
  previous: 'Precedente',
  next: 'Successivo',
  date_validation_msg: 'Si prega di inserire una data valida',
  freeze_inventory: "Congela l'inventario",
  create_inventory_list: 'Crea elenchi di inventario',
  correct_inventory: 'Inventario corretto',
  create_delta_list: 'Crea elenchi delta',
  unfreeze_inventory_end:
    "Sblocca l'inventario e termina la contabilità delle scorte",
  download_pdf: 'Scarica PDF',
  download_excel: 'Scarica Excel',
  download__delta_list_pdf: 'Scarica la lista delta PDF',
  unfreeze_inventory: "Sblocca l'inventario",
  no_transferring: '(Nessuna trasmissione degli elementi possibile)',
  download_as: '(Scarica come PDF e salva, quindi stampa)',
  transfer_items:
    '(Trasferisci elementi dalla o alla correzione della contabilità delle scorte)',
  what_change:
    '(Quali sono stati i cambiamenti effettuati durante la contabilità delle scorte)',
  step_1: 'Passo 1',
  step_2: 'Passo 2',
  step_3: 'Passo 3',
  step_4: 'Passo 4',
  step_5: 'Passo 5',
  actual_quantity: 'Quantità Effettiva',
  delta: 'Delta',
  no_data_found: 'Nessun dato trovato',
  ms: 'Sig.ra',
  Ms: 'Sig.ra',
  diverse: 'Diverse',
  Diverse: 'Diverse',
  language: 'Lingua',
  phone_number: 'Numero di Telefono',
  sleeping_facilities: 'Strutture per il Sonno',
  number: 'Numero',
  until: 'Fino a',
  all_logs: 'Tutti i log',

  drag_drop_csv_files_msg:
    'Trascina e rilascia solo file .csv qui, o fai clic per selezionare i file .csv. Carica file fino a 10 MB.',
  drag_drop_csv_15MB_files_msg:
    'Trascina e rilascia solo file .csv qui, o fai clic per selezionare i file .csv. Carica file fino a 15 MB.',
  processing_data: 'Elaborazione dati',
  failed: 'Non riuscito',
  file_uploaded_successfully: 'File caricato correttamente.',
  uploaded_on: 'Ultimo caricato il',
  by: 'da',
  transferred: 'trasferito',
  internal_server_error: 'Errore interno del server',
  cannot_upload_csv_in_worng_category:
    'Impossibile caricare il file CSV in categoria errata',
  upload_date: 'Data di caricamento',
  location: 'Posizione',

  /* OMS */
  object_management_system: 'Sistema di gestione degli oggetti',
  units: 'Unità',
  oms: 'OMS',
  object_list: 'Liste degli oggetti',
  unit_type: 'Tipo di unità',
  manage_units: 'Gestire le unità',
  manage_objects: 'Gestire gli oggetti',
  apartment_quota: 'Quote degli oggetti',
  quota: 'Quote',
  stwe_number: 'Numero STWE',
  floor: 'Piano',
  floor_number: 'Numero Piano',
  rooms: 'Stanze',
  room_number: 'Numero Stanze',
  number_of_rooms: 'Anzahl der Räume',
  living_space: 'Superficie abitabile (m²)',
  garage_number: 'Garage no.',
  garage_quota: 'Quota garage',
  garage_type_is_required: 'È richiesto il tipo di garage',
  garage_quota_is_required: 'La quota garage è necessaria',
  garage_number_is_required: 'Il numero di garage è richiesto',
  add_another_garage: 'Aggiungere un altro garage',
  no_abbreviation: 'N.',
  season_name: 'Nome della stagione',
  construction_year: 'Anno di construzione',
  from_date: 'Data di inizio',
  to_date: 'Data di fine',
  add_new_object: 'Aggiungere un nuovo oggetto',
  add_object: 'Aggiungere un oggetto',
  add_new_unit: 'Aggiungere un nuovo unità',
  add_new_apartment: 'Aggiungere un nuovo appartamento',
  add_apartment: 'Aggiungere un appartamento',
  edit_object: 'Modifica oggetto',
  status_changed: 'Stato modificato',
  features: 'Funzionalità',
  picture: 'Immagine',
  text: 'Testo',
  cannot_get_apartment_details:
    "Impossibile ottenere i dettagli dell'appartamento",
  must_be_greater_than_zero: 'Deve essere maggiore di 0',
  basic_information: 'Informazioni di base',
  copy: 'Copia',
  save_object: 'Salva oggetto',
  save_apartment: 'Salva Appartamento',
  add_owner: 'Aggiungi proprietario',
  enter_owner: 'Inserire il proprietario',
  no_owner: 'Non è ancora stato associato un proprietario ,a questo oggetto.',
  owner_is_required: "Vous devez associer au moins un propriétaire à l'unité !",
  ical_link: 'Collegamento iCal',
  copied: 'Copiato',
  copy_to_clipboard_ctrl_c_enter: 'Copia negli appunti: Ctrl+C, Enter',
  edit_ownership: 'Modifica della proprietà',
  delete_ownership: 'Cancellare la proprietà',
  select_ownership: 'Selezionare la proprietà',
  actions: 'Azioni',
  price: 'Prezzo',
  garage: 'Garage',
  garage_type: 'Tipo di garage',
  quantity: 'Quantità',
  store: 'Affari',
  restaurant: 'Ristorante',
  select_object_type: 'Selezionare il tipo di oggetto',
  enter_apartment_number: 'Inserire il numero di STWE/appartamento',
  enter_stwe_number: 'Inserisci il numero STWE',
  select_floor: 'Selezionare il piano',
  enter_living_space: 'Inserisci lo spazio abitativo',
  select_rooms: 'Seleziona le stanze',
  select_garage_type: 'Selezionare il tipo di garage',
  enter_garage_number: 'Inserire il numero di garage.',
  enter_street: 'Entrare in strada',
  enter_quantity: 'Anzahl eingeben',
  enter_zip_code: 'PLZ eingeben',
  select_country: 'Inserire il codice postale',
  select_unit_type: 'Selezionare il tipo di unità',
  enter_apartment_quota: 'Immettere le quote degli oggetti',
  enter_quota: 'Inserire le quote',
  enter_garage_quota: 'Inserire le quote del garage',
  enter_house_number: 'Inserire il numero civico',
  enter_town: 'Inserire la posizione',
  enter_object_name: "Inserire il nome dell'oggetto",
  enter_object_addition: "Inserire l'aggiunta di oggetti",
  enter_construction_year: "Inserire l'anno di construzione",
  enter_address_adittion: "Inserire l'anno di suffisso",
  confirm_remove_garage:
    'Vous êtes sûr que cela supprimera tous les garages sauvegardés de cet appartement.',
  object_type_is_required: 'Il tipo di oggetto è richiesto',
  object_name_is_required: "Il nome dell'oggetto è obbligatorio",
  object_number_is_required: "È richiesta l'aggiunta di oggetti",
  object_number: 'Objektnummer',
  unit_number: 'Numero di unità',
  object_name: "Nome dell'oggetto",
  object_addition: 'Aggiunta di oggetti',
  permanent_rental: 'Affitto permanente',
  vacation_rental: 'Affitto per le vacanze',
  unknown: 'Sconosciuto',
  single_garage: 'Garage singolo',
  indoor_parking_space: 'Posto auto al coperto',
  outdoor_parking_lot: "Posto auto all'aperto",
  own_use: 'Uso personale',
  studio: 'Studio',
  '1': '1',
  '1.5': '1,5',
  '2': '2',
  '2.5': '2,5',
  '3': '3',
  '3.5': '3,5',
  '4': '4',
  '4.5': '4,5',
  '5': '5',
  '5.5': '5,5',
  '6+': '6+',
  'floor_-2': '2° piano interrato',
  'floor_-1': '1° piano interrato',
  floor_0: 'piano terra',
  floor_1: '1° piano',
  floor_2: '2° piano',
  floor_3: '3° piano',
  floor_4: '4° piano',
  floor_5: '5° piano',
  floor_6: '6° piano',
  change_caretaker: 'Cambia custode',
  change_caretaker_info: 'Vuoi davvero cambiare il Custode?',
  change: 'Cambia',
  remove_caretaker: 'Rimuovi il Custode',
  remove_caretaker_info: 'Vuoi veramente rimuovere il Custode?',
  remove: 'Rimuovi',
  select_caretaker: 'Seleziona custode',
  caretaker_is_required: 'Il custode è necessario',
  enter_first_name: 'Inserire il nome',
  enter_last_name: 'Inserire il cognome',
  enter_email_address: "Inserisci l'indirizzo e-mail",
  enter_phone_number: 'Inserisci il telefono',
  enter_title: 'Inserisci il titolo',
  add_notes: 'Aggiungi note',
  edit_unit: 'Modifica Unità',
  add_new_garage: 'Aggiungi un nuovo garage',
  add_garage: 'Aggiungere garage',

  /**DMS Service */
  document_management_system: 'Sistema di gestione dei documenti',
  dms: 'DMS',
  all_documents: 'Tutti i documenti',
  upload_document: 'Carica documento',
  edit_document: 'Modifica del documento',
  document_name: 'Nome del documento',
  owner_name: 'Nome del proprietario',
  availability: 'Disponibilità',
  no_of_documents: 'Numero di documenti',
  select_object: 'Seleziona oggetto',
  documents: 'Documenti',
  general_document_deleted: 'Il documento è stato cancellato',
  delete_general_document: 'Elimina documento',
  general_document_not_found: 'Documento non trovato',
  general_document_udpate_failed: 'Aggiornamento del documento non riuscito',
  general_document_created: 'Il documento è stato creato',
  general_document_creation_failed: 'Creazione del documento fallita',
  general_document_updated: 'Documento aggiornato con successo',
  owner_documents: 'Documenti del proprietario',
  import_file: 'File di importazione',
  the_file_field_is_required: 'Il campo file è obbligatorio',

  apartment_name: "Nome dell'appartamento",
  title: 'Titolo',
  first_name_is_required: 'Il nome è richiesto',
  last_name_is_required: 'Il cognome è richiesto',
  status_is_required: 'Lo status è obbligatorio',
  living_space_is_required: 'La superficie abitabile è obbligatoria',
  stwe_number_is_required: 'Il numero STWE è obbligatorio',
  apartment_quota_is_required: 'Il Quote degli oggetti è richiesto',
  street_name_is_required: 'Il nome della strada è richiesto',
  country_is_required: 'Il paese è richiesto',
  invalid_phone_number: 'Numero di telefono non valido',
  appartment_is_required: "L'appartamento è richiesto",
  house_number_is_required: 'Il numero civico è richiesto',
  town_is_required: 'Il Città è richiesto',
  zip_code_is_required: 'Il codice postale è richiesto',
  phone_number_is_required: 'Il numero di telefono è richiesto',

  mr: 'Sig.',
  Mr: 'Sig.',
  mrs: 'Sig.ra',
  misc: 'Varie',

  insurance_policy: 'Insurance policy',
  stwe_foundation_contract: 'STWE foundation contract',
  stwe_regulations: 'STWE regulations',
  house_rules: 'House rules',
  annual_invoice: 'Annual invoice',
  annual_report: 'Annual report',
  minutes: 'Minutes',
  investment_plan: 'Investment plan',
  budget: 'Budget',
  correspondence: 'Correspondence',
  invitation: 'Invitation',
  audit_report: 'Rapporto di revisione',
  power_of_attorney: 'Procura',
  plans: 'Piani',
  service_charge_invoice: 'Fattura di servizio',
  offer: 'Offerta',
  report: 'Rapporto',
  locking_plan: 'Piano di bloccaggio',

  assignment: 'Assignment',
  assignment_placeholder: 'Select Assignment',
  assignment_required: 'Assignment is required',
  await_contact_details: 'Contatti del custode',

  year: 'Year',
  year_placeholder: 'Select Year',
  year_required: 'Year is required',

  tag: 'Tag',
  tag_placeholder: 'Select Tag',
  tag_required: 'Tag is required',

  document_upload_info: `Please upload a document first and assign for it the corresponding assignment,
   the appropriate designation and the respective year.`,
  document_upload_info2: `Selezionare prima un'unità per poter assegnare il documento a un proprietario.`,
  current_owner: 'Current Owner',
  apartment_number: "Numero dell'appartamento",
  previous_owner: 'Previous owner',

  document_owner: 'Document - Owner',
  document_units: 'Document - Unità',
  total: 'Total',

  select_owners_validation: 'Please select owners',
  select_object_validation: 'Please select objects',
  document_upload: 'Document Upload',
  reset: 'Azzeramento',
  reset_selection: 'Azzeramento della selezione',
  reset_warning:
    'Si desidera davvero resettare la selezione effettuata finora?',
  document_properties: 'Proprietà del documento',
  remove_assignment: 'Rimuovi incarico',
  remove_assignment_warning:
    'Si desidera davvero rimuovere le unità già assegnate?',
  document_uploading: 'Il documento è in fase di caricamento',
  assigned_units: 'Unità assegnate',
  other_units: 'Altre unità',
  delete_document_info:
    'Si vuole veramente cancellare il documento {0} in modo irreversibile?',
  delete_document_notes:
    'Si prega di notare che il documento selezionato è ancora collegato alle unità e ai proprietari seguenti:',
  delete_document_warning: `Attenzione: Facendo clic su "Elimina", il documento selezionato verrà cancellato irreversibilmente anche da tutte le unità e i proprietari collegati.`,
  document_deleted_successfully: 'Il documento è stato eliminato',

  /**FAQ Service */
  frequently_ask_question: 'Domanda frequente',
  faq: 'FAQ',
  all_faq: 'Tutte le FAQ',
  success: 'Successo',
  group: 'Gruppi',
  all_groups: 'Tutti i gruppi',
  add_faq: 'Aggiungi FAQ',
  edit_faq: 'Modifica FAQ',
  delete_faq: 'Elimina FAQ',
  select_group: 'Seleziona gruppo',
  faq_deleted_successfully: 'FAQ cancellate con successo',

  faq_group: 'Gruppo FAQ',
  add_group: 'Aggiungi gruppo',
  edit_group: 'Modifica gruppo',
  delete_group: 'Elimina gruppo',
  invalid_data: 'I dati forniti non sono validi',
  download: 'Scaricare',
  download_image: 'Scarica immagine',
  select_date: 'Seleziona data',
  select_date_time: 'Seleziona data/ora',

  /*Internet Status*/
  wifi: 'La connessione a Internet è stata ripristinata',
  nowifi: 'Stai navigando in modalità offline',
  refresh: 'Aggiorna',

  /*required*/
  required_first_name: 'Il nome è obbligatorio.',
  required_last_name: 'Il cognome è obbligatorio.',
  required_email: "L'email è obbligatoria.",
  required_cellphone: 'Il cellulare è obbligatorio.',
  required_role: 'Il ruolo è obbligatorio.',
  required_address: "L'indirizzo è obbligatorio.",
  required_type: 'Il tipo è obbligatorio.',
  required_document_name: 'Il nome del documento è obbligatorio.',
  required_upload: 'È necessario caricare un file',
  required_group_name: 'Il nome del gruppo è obbligatorio',
  required_status: 'Lo stato è richiesto',
  required_owner: 'Il proprietario è richiesto',
  required_ownership: 'È richiesta la proprietà.',
  only_one_file_is_allowed: 'È consentito solo un file',
  required_password: 'È richiesta la password',
  required_sleeping_facilities: 'Sono richieste strutture per dormire',
  required_pets_allowed: 'Il campo animali ammessi è obbligatorio',

  /*Message*/
  email_taken: "L'email è già stata presa",

  /* Appartment Address Form*/
  address_location: 'Indirizzo e posizione',
  address_adittion: "Suffisso dell'indirizzo",
  distance_lake: 'Distanza dal lago',
  distance_shopping: 'Distanza Shopping',
  distance_restaurant: 'Distanza ristorante',
  distance_city: 'Distanza città',
  distance_train_station: 'Distanza stazione ferroviaria',
  distance_bank_atm: 'Distanza banca / bancomat',
  distance_doctor: 'Distanza medico',
  the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency:
    'La mappa/indicazioni di Google Maps facilita agli ospiti la navigazione verso il vostro appartamento e offre la massima trasparenza',
  type_is_required: 'Il tipo è richiesto',
  pets_allowed: 'Animali ammessi',

  /**lyrics form */
  headline: 'Titolo',
  full_description: 'Descrizione completa',
  short_description: 'Descrizione breve',
  directions: 'Indicazioni',

  /**Settings Form */
  season_and_prices: 'Stagioni e prezzi',
  please_assign_price:
    "Si prega di assegnare le stagioni disponibili all'appartamento e di indicare un prezzo",
  assigned: 'Assegnato',
  adjust_order_of_images:
    "L'ordine delle immagini può essere facilmente regolato tramite trascinamento e rilascio.",
  delete_image: 'Elimina Immagine',

  // Newsletter
  newsletter_subscribed: 'Newsletter iscritta',
  newsletter: 'Newsletter',
  newsletter_contact: 'Contatto Newsletter',
  newsletters: 'Newsletters',
  newsletter_overview: 'Panoramica Newsletter',
  draft: 'Bozza',
  drafts: 'Bozze',
  group_of_recipients: 'Gruppo di destinatari',
  all_newsletters: 'Tutte le newsletters',
  new_newsletter: 'Nuova newsletter',
  create_new_newsletter: 'Crea nuova newsletter',
  recipients: 'Destinatari',
  subject: 'Oggetto',
  all_draft: 'Tutte le bozze',
  message: 'Messaggio',
  attach_file: 'Allega file',
  attachment: 'Allegati:',
  attachments: 'Allegati:',
  preview: 'Anteprima',
  save_as_draft: 'Salva come bozza',
  compose_message: 'Componi messaggio',
  preview_message: 'Anteprima newsletter',
  download_our_app_now: 'Scarica la nostra app adesso',
  about_us: 'Su di noi',
  customer_care: 'Assistenza clienti',
  contact_us: 'Contattaci',
  privacy_policy: 'Politica sulla privacy',
  subscribe: 'Iscriviti',
  unsubscribe: 'Annulla iscrizione',
  select_recipient_group: 'Seleziona destinatario',
  edit_newsletter: 'Modifica newsletter',
  delete_newsletter: 'Elimina newsletter',
  newsletter_deleted: 'Newsletter eliminata.',
  newsletter_delete_error: 'Eliminazione newsletter non riuscita.',
  newsletter_sent_sucessfully: 'Newsletter inviata con successo',
  newsletter_saved_to_draft_sucessfully:
    'Newsletter salvata in bozza con successo',
  newsletter_updated_sucessfully: 'Newsletter aggiornata con successo',
  hello: 'Ciao',
  to_send: 'Da inviare',
  save_and_send: 'Salva e invia',
  recipient_group_field_is_required: 'Il campo gruppo destinatario è richiesto',
  subject_field_is_required: 'Il campo oggetto è richiesto',
  message_field_is_required: 'Il campo messaggio è obbligatorio',
  send_newsletter: 'Invia newsletter',
  attention_notification_will_be_send_continue:
    'Attenzione! La notifica sarà inviata in modo continuativo',
  privacy: 'Privacy',
  imprint: 'Impronta',
  text_search: 'Ricerca testuale',
  date: 'Data',
  dispatched: 'Inviato',
  app: 'Applicazione',
  connect_with_us: 'Connettiti con noi',
  website: 'Sito web',
  newsletter_unsubscribe: 'Cancellazione della newsletter',

  first_select_the_unit_then_the_owner:
    "Seleziona prima l'unità, poi il proprietario",

  enter_description: 'Titolo inserito',
  // Delete Owner Message
  delete_owner_confirmation:
    'Vuoi davvero cancellare il {0} {1}, {2} in modo irrevocabile?',
  linked_units_message:
    'Si noti che il {0} selezionato è ancora collegato alle seguenti unità:',
  delete_attention_message:
    'Attenzione: Facendo clic su Elimina si cancella irrevocabilmente il {0} selezionato da tutte le unità collegate.',

  no_object_associated_with_message:
    'Non ci sono altri oggetti associati al {0} selezionato..',
  all_roles: 'Tutti i ruoli',
  upload_failed: 'Caricamento fallito!',
  attention: 'Attenzione:',
  could_not_be_uploaded: 'impossibile caricare.',
  documents_are_uploaded: 'I documenti sono in fase di caricamento',
  close: 'Chiudi',
};

export default it;
