import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import RoleResource from 'api/roles';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import UserListItem from 'components/user/UserListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import {
  DataWrapper,
  PermissionItemSchema,
  UserSchema,
} from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BiDownload, BiFilter, BiSearch } from 'react-icons/bi';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

import OMSOwnersResource from 'api/oms-owners';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import FileSaver from 'file-saver';
import useCustomToast from 'hooks/useCustomToast';
import { useDebounce } from 'hooks/useDebounce';
import { Helmet } from 'react-helmet';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import AccessControl from 'services/AccessControl';
import { RootState } from '../../../store';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  roleId: string;
  keyword: string;
  isAddressForwarding: boolean;
  isDataSharing: boolean;
  isNewsletterSubscribed: boolean;
}

const UserList: React.FC = () => {
  const userAPI = new UserResource();
  const roleAPI = new RoleResource();
  const omsOwnersAPI = new OMSOwnersResource();

  const checkAddOwnerPermission = useCheckPermission(
    [PermissionRequest['read:user'], PermissionRequest['manage:owner']],
    routes.users.owner.add
  );

  const { search } = useLocation();
  const history = useHistory();
  let searchParams = new URLSearchParams(search);
  const url_roleId = searchParams.get('roleId') ?? '';
  const url_keyword = searchParams.get('keyword') ?? '';
  const urlPageSize =
    searchParams?.get('pageSize') && Number(searchParams.get('pageSize'));
  const urlCurrentPage =
    searchParams?.get('currentPage') && Number(searchParams.get('currentPage'));
  const urlIsAddressForwarding =
    searchParams.get('isAddressForwarding') === 'true';
  const urlHasNewsletterSubscribed =
    searchParams.get('isNewsletterSubscribed') === 'true';
  const urlIsDataisDataSharing = searchParams.get('isDataSharing') === 'true';

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: urlCurrentPage ? urlCurrentPage : INITIAL_CURRENT_PAGE,
    pageSize: urlPageSize ? urlPageSize : DEFAULT_PAGE_SIZE,
    roleId: url_roleId ?? '',
    keyword: url_keyword ?? '',
    isAddressForwarding: urlIsAddressForwarding,
    isDataSharing: urlIsDataisDataSharing,
    isNewsletterSubscribed: urlHasNewsletterSubscribed,
  });

  const roleList = useQuery<PermissionItemSchema[]>(
    'roleList',
    () => roleAPI.list().then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { showErrorToast } = useCustomToast();

  const downloadContactSharing = useMutation(
    omsOwnersAPI.downloadContactSharing,
    {
      onSuccess: (data) => {
        FileSaver.saveAs(
          data.data,
          data.headers?.['x-suggested-name'] || 'Contact-sharing.csv'
        );
      },
      onError: () => {
        showErrorToast();
      },
    }
  );

  const { keyword, ...restFilters } = filterParams;
  const debouncedKeyword = useDebounce(keyword);

  const userList = useQuery<DataWrapper<UserSchema[]>>(
    ['userList', debouncedKeyword, restFilters],
    async () => {
      const queryParams: Record<string, string> = {
        page: String(filterParams.currentPage),
        limit: String(filterParams.pageSize),
      };

      if (filterParams.roleId) queryParams.role_id = filterParams.roleId;
      if (filterParams.keyword) queryParams.keyword = debouncedKeyword;
      if (filterParams.isAddressForwarding)
        queryParams.address_forwarding_only = '1';
      if (filterParams.isDataSharing) queryParams.allow_to_share_data = '1';
      if (filterParams.isNewsletterSubscribed)
        queryParams.is_newsletter_subscribed = '1';
      const response = await userAPI.list(queryParams);
      return response?.data;
    },
    {
      onError: () => showErrorToast(),
    }
  );

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      roleId: '',
      keyword: '',
      isAddressForwarding: false,
      isNewsletterSubscribed: false,
      isDataSharing: false,
    }));
    history.push(routes.users.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */

  const updateUrl = useCallback(
    (updatedData: Partial<FilterParams>) => {
      const data = {
        roleId: filterParams.roleId,
        keyword: filterParams.keyword,
        isAddressForwarding: filterParams.isAddressForwarding,
        isNewsletterSubscribed: filterParams.isNewsletterSubscribed,
        isDataSharing: filterParams.isDataSharing,
        ...updatedData,
      };

      const searchURL = changeURL(data);
      history.push(`?${searchURL}`);
    },
    [filterParams, history]
  );

  const handleCheckBoxFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: checked,
    }));

    updateUrl({ [name]: checked });
  };

  const handleInputChange: ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));

    updateUrl({ [name]: value });
  };

  const { role } = useSelector((state: RootState) => ({
    role: state?.data?.auth?.user?.role,
  }));

  const clickDownload = () => downloadContactSharing.mutate();

  useEffect(() => {
    updateUrl(filterParams);
  }, [updateUrl, filterParams]);

  return (
    <>
      <Helmet>
        <title>
          {strings.user} | {strings.user_list}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.user_list}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.user_list}
          </Heading>

          <div>
            <Menu>
              <ButtonGroup>
                <Button
                  size="lg"
                  w="fit-content"
                  colorScheme="primary"
                  variant="outline"
                  isLoading={downloadContactSharing.isLoading}
                  rightIcon={<BiDownload size="24" />}
                  onClick={clickDownload}>
                  {strings.csv_datasharing}
                </Button>

                <MenuButton
                  p={2}
                  transition="all 0.2s"
                  h="12"
                  pl="6"
                  pr="6"
                  borderRadius="sm"
                  bg="primary.400"
                  _hover={{ borderBottom: 'primary.300' }}
                  _expanded={{ borderBottom: 'primary.400' }}>
                  <Flex gap={2} alignItems="center">
                    <Box color="white">{strings.add_new_user}</Box>
                    <Box color="white">
                      <BsFillCaretDownFill />
                    </Box>
                  </Flex>
                </MenuButton>
              </ButtonGroup>

              <MenuList>
                {role === 'admin' && (
                  <AccessControl
                    allowedPermissions={[
                      PermissionRequest['read:user'],
                      PermissionRequest['manage:admin'],
                    ]}>
                    <RouterLink to={routes.users.add + '/?role_id=1'}>
                      <MenuItem>{strings.admin}</MenuItem>
                    </RouterLink>
                  </AccessControl>
                )}
                <AccessControl
                  allowedPermissions={[
                    PermissionRequest['read:user'],
                    PermissionRequest['manage:user'],
                  ]}>
                  <RouterLink to={routes.users.add + '/?role_id=2'}>
                    <MenuItem>{strings.employee}</MenuItem>
                  </RouterLink>
                </AccessControl>
                <AccessControl
                  allowedPermissions={[
                    PermissionRequest['read:user'],
                    PermissionRequest['manage:user'],
                  ]}>
                  <RouterLink to={routes.users.add + '/?role_id=3'}>
                    <MenuItem>{strings.employee_with_ticket_support}</MenuItem>
                  </RouterLink>
                </AccessControl>

                <AccessControl
                  allowedPermissions={[
                    PermissionRequest['read:user'],
                    PermissionRequest['manage:caretaker'],
                  ]}>
                  <RouterLink to={routes.users.careTaker.add}>
                    <MenuItem>{strings.caretaker}</MenuItem>
                  </RouterLink>
                </AccessControl>

                <AccessControl
                  allowedPermissions={[
                    PermissionRequest['read:user'],
                    PermissionRequest['manage:owner'],
                  ]}>
                  <MenuItem onClick={checkAddOwnerPermission}>
                    {strings.owner}
                  </MenuItem>
                </AccessControl>
              </MenuList>
            </Menu>
          </div>
        </Flex>
        <Accordion
          defaultIndex={0}
          bg="white"
          borderColor="white"
          allowToggle
          boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <Stack
                  direction={['column', 'column', 'row']}
                  spacing="4"
                  alignItems={['initial', 'initial', 'flex-end']}>
                  <Grid
                    gap="4"
                    templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                    flex="1">
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.user}</FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            h="100%"
                            pointerEvents="none"
                            children={<BiSearch />}
                            color="gray.800"
                          />
                          <Input
                            type="text"
                            name="keyword"
                            value={filterParams.keyword}
                            onChange={handleInputChange}
                            placeholder={`${strings.last_name}, ${strings.first_name}`}
                            size="lg"
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.role}</FormLabel>
                        <CustomChakraSelect
                          placeholder={strings.select_role}
                          rounded="sm"
                          value={filterParams.roleId}
                          name="roleId"
                          size="lg"
                          onChange={handleInputChange}>
                          {roleList?.data?.map((role) => {
                            return (
                              <option key={role.id} value={role.id}>
                                {strings.getString(
                                  role.name.split(' ').join('_')
                                )}
                              </option>
                            );
                          })}
                        </CustomChakraSelect>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <Checkbox
                          size="lg"
                          name="isNewsletterSubscribed"
                          onChange={handleCheckBoxFilterChange}
                          isChecked={filterParams.isNewsletterSubscribed}>
                          <Text fontSize="md">
                            {strings.newsletter_subscribed}
                          </Text>
                        </Checkbox>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <Checkbox
                          size="lg"
                          name="isAddressForwarding"
                          onChange={handleCheckBoxFilterChange}
                          isChecked={filterParams.isAddressForwarding}>
                          <Text fontSize="md">
                            {strings.address_forwarding}
                          </Text>
                        </Checkbox>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <Checkbox
                          size="lg"
                          name="isDataSharing"
                          onChange={handleCheckBoxFilterChange}
                          isChecked={filterParams.isDataSharing}>
                          <Text fontSize="md">
                            {strings.data_sharing_activated}
                          </Text>
                        </Checkbox>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Button
                    size="lg"
                    w="fit-content"
                    colorScheme="primary"
                    variant="outline"
                    onClick={handleReset}>
                    {strings.reset_filter}
                  </Button>
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.user}</Th>
                  <Th>{strings.address}</Th>
                  <Th>{strings.email}</Th>
                  <Th>{strings.phone}</Th>
                  <Th>{strings.role}</Th>
                  <Th textAlign="center">{strings.FA_status}</Th>
                  <Th></Th>
                </Tr>
              </Thead>

              <Tbody>
                {userList.data?.data?.map((userData, index) => (
                  <UserListItem
                    key={userData.id}
                    userData={userData}
                    index={startingSN + index}
                    search={search}
                  />
                ))}
                {userList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={userList}
        />
      </Stack>
    </>
  );
};

export default UserList;
